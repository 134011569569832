/** @format */

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
	font-family: 'Inter', sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.nav {
	max-width: 980px;
	margin: 0 auto;
	width: 100%;
	padding: 24px 0 16px 16px;
}

.nav img {
	width: 150px;
}

.container {
	max-width: 980px;
	margin: 0 auto;
	width: 100%;
	padding: 0 16px;
}

.card {
	background: #fff;
	border-radius: 2px;
	display: inline-block;
	width: 100%;
	padding: 2rem;
	margin-top: 4rem;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	border-radius: 10px;
}

.card-info {
	display: flex;
	justify-content: start;
	align-items: center;
}

.card-header {
	font-size: 22px;
	color: #010101;
	flex: 1;
	font-family: 'Inter', 'sans-serif';
}

.device-info {
	display: flex;
	justify-content: start;
	flex-direction: column;
}

.browser {
	font-size: 14px;
	font-weight: 400;
}

.browser-text {
	font-size: 14px !important;
	color: #23358c;
}

.os {
	font-size: 14px;
	font-weight: 400;
}

.os-text {
	font-size: 14px !important;
	color: #23358c;
}

.form {
	padding: 2rem 0;
}

.input {
	padding: 8px;
	border: 1px solid #dadce0;
	width: 400px;
	border-radius: 5px;
	margin-left: 4px;
}

.span {
	display: block;
	color: #616161;
	margin-top: 1.5rem;
}

.button {
	background-color: #23358c;
	display: block;
	margin-top: 3rem;
	padding: 10px 20px;
	color: #fff;
	border: 1px solid #23358c;
	border-radius: 4px;
	text-transform: uppercase;
	letter-spacing: 1px;
	cursor: pointer;
	font-family: 'Inter', sans-serif;
}

.success-div {
	margin-top: 30px;
}

.response-title {
	font-size: 1rem;
	color: #303030;
	font-weight: 400;
}

.response-subtitle {
	font-size: 1rem;
	color: #303030;
	font-weight: 400;
}
